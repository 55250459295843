import React from 'react';

import StaticPageLayout from '../components/staticPageLayout';
import SEO from '../components/seo';
import styled from 'styled-components';

const Content = styled.div`
  max-width: 780px;
  h2 {
    font-size: 28px;
    margin-top: 20px;
  }
  li,
  p {
    margin-bottom: 0;
  }
  ol {
    > li {
      margin-bottom: 0px;
      margin-top: 20px;
    }
  }
`;

const EcoPage = () => {
  return (
    <StaticPageLayout title="Jesteśmy EKO!">
      <SEO title="Jesteśmy EKO!" />
      <Content>
        <p>
          W dzisiejszych czasach wszyscy musimy dbać o środowisko, a my jako studio projektowe
          musimy zwracać wszystkim na to szczególną uwagę. Nie chcemy by nasze działania były
          odbierane jako działania PR-owe a jako działania konieczne.
        </p>
        <ol>
          <li>
            Nie posiadamy własnych materiałów drukowanych.
            <ul>
              <li>Nasza oferta, portfolio są w wersji cyfrowej, tak samo jak oferty i faktury.</li>
              <li>
                Od 2019 roku nawet nie posiadamy własnych wizytówek, po spotkaniu przesyłamy e-maila
                bądź sms z naszymi danymi kontaktowymi.
              </li>
            </ul>
          </li>
          <li>
            Jak już musimy drukować (np. umowy) to drukujemy:
            <ul>
              <li>Na papierze z recyklingu</li>
              <li>Dwustronnie</li>
              <li>Możliwie jak najmniejszym krojem pisma przy jak najmniejszych marginesach</li>
            </ul>
          </li>
          <li>
            Pracujemy zdalnie – nasz zespół w większości pracuje zdalnie, przez co nie generujemy
            jako zespół FNC dodatkowych zanieczyszczeń związanych z przyjazdem do siedziby.
          </li>
          <li>
            Jeździmy zero-emisyjnie – jeżeli przyjeżdżamy na spotkanie w Krakowie, staramy się
            przyjechać na hulajnodze elektrycznej, pójść pieszo bądź na rowerze (całorocznie).
            Wszyscy nasi pracownicy mają swoje rowery.
          </li>
          <li>
            Gdy dystans jest większy (poza Krakowem) często prowadzimy spotkania online. Jeżeli już
            wybieramy się np. do Warszawy jeździmy zgodnie z zasadami Eko-drivingu.
          </li>
          <li>
            Nasz samochód firmowy – jest nisko-emisyjny (układ hybrydowy). Z biegiem czasu wraz z
            rozwojem infrastruktury planujemy zakup samochodu elektrycznego.
          </li>
          <li>
            Zawsze staramy się przekonać naszych klientów do zmniejszenia ilości materiałów
            drukowanych oraz wybór papieru recyklingowego (większość naszych klientów za naszą
            namową zmienia swoje nastawienie).
          </li>
          <li>Korzystamy ze sprzętu komputerowego spełniającego normy niskiego zużycia prądu.</li>
          <li>W słoneczne dni produkujemy tyle prądu, że starcza nam na prace naszych urządzeń.</li>
          <li>Pijemy wodę z kranu, segregujemy śmieci – ale to wszyscy robią :)</li>
        </ol>
      </Content>
    </StaticPageLayout>
  );
};

export default EcoPage;
